
import { defineComponent } from 'vue';
import { mdiPencil, mdiDelete, mdiEyeOff, mdiArrowRight } from '@mdi/js';

export default defineComponent({
  components: {},
  setup() {
    return {
      mdiPencil,
      mdiDelete,
      mdiEyeOff,
      mdiArrowRight,
    };
  },
});
