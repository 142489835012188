<style lang="scss">
#backend {
  .category-keywords {
  }
  .category-keywords-overview {
    margin-top: 50px;
    .keyword-inner {
      border-bottom: 1px solid #eee;
      padding: 5px 10px;
      &:last-child {
        border-bottom: 0;
      }
      .keyword-txt {
        //border:1px solid red;
        padding: 0.375rem 0rem;
      }
      .control-box {
        display: flex;
        justify-content: flex-end;
        .btn {
          //border:1px solid red;

          svg {
            width: 25px;
            height: 25px;
            fill: #888;
          }
          &:hover {
            svg {
              fill: #333;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid category-keywords-all">
    <div class="headline-h1"><h1>Keywords hinzufügen</h1></div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group"><span class="label-input">Keyword Deutsch</span> <input type="text" value="not yet implemented" /></div>
      </div>
      <div class="col-md-4">
        <div class="form-group"><span class="label-input">Keyword Englisch</span> <input type="text" value="not yet implemented" /></div>
      </div>
      <div class="col-md-4 justify-content-center align-self-center">
        <button class="btn-std btn-color-save">Speichern</button>
      </div>
    </div>

    <div class="category-keywords-overview">
      <div class="row">
        <div class="col-lg-6">
          <div class="headline-h1"><h1>Keywords Übersicht</h1></div>
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Laufen</div>
              </div>
              <div class="col-4 justify-content-end align-self-center">
                <div class="control-box">
                  <div class="btn btn-save" title="Speichern"><Icon :path="mdiPencil" /></div>
                  <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Magazin</div>
              </div>
              <div class="col-4 justify-content-end align-self-center">
                <div class="control-box">
                  <div class="btn btn-save" title="Speichern"><Icon :path="mdiPencil" /></div>
                  <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Developement</div>
              </div>
              <div class="col-4 justify-content-end align-self-center">
                <div class="control-box">
                  <div class="btn btn-save" title="Speichern"><Icon :path="mdiPencil" /></div>
                  <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">State</div>
              </div>
              <div class="col-4 justify-content-end align-self-center">
                <div class="control-box">
                  <div class="btn btn-save" title="Speichern"><Icon :path="mdiPencil" /></div>
                  <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Restrictions</div>
              </div>
              <div class="col-4 justify-content-end align-self-center">
                <div class="control-box">
                  <div class="btn btn-save" title="Speichern"><Icon :path="mdiPencil" /></div>
                  <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
        <div class="col-lg-6">
          <div class="headline-h1"><h1>Kategorien Übersicht</h1></div>
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Startseite</div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Marketplaces</div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">Events</div>
              </div>
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="keyword-inner">
            <div class="row">
              <div class="col-8 align-self-center">
                <div class="keyword-txt">History</div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiPencil, mdiDelete, mdiEyeOff, mdiArrowRight } from '@mdi/js';

export default defineComponent({
  components: {},
  setup() {
    return {
      mdiPencil,
      mdiDelete,
      mdiEyeOff,
      mdiArrowRight,
    };
  },
});
</script>
